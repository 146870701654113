import { readState } from "@/__main__/app-state.mjs";
import { postData } from "@/__main__/get-data.mjs";
import { appURLs } from "@/app/constants.mjs";
import getBearerToken from "@/feature-auth/utils/get-auth-request-header.mjs";
import { sendLeagueGameRecord } from "@/feature-battles/api.mjs";
import {
  ALL_QUEUES,
  DEFAULT_OVERLAY_ICON,
  DEFAULT_OVERLAY_LOGO,
  UNHANDLED_MAP,
  UNHANDLED_QUEUE,
} from "@/feature-battles/constants.mjs";
import {
  Criteria,
  criteriaToEventMap,
  multiKillToEventMap,
} from "@/feature-battles/constants-lol.mjs";
import type {
  OverlayCriteria,
  OverlayEventInfo,
} from "@/feature-battles/event-hooks-util.mjs";
import {
  getHashedSecurityHeader,
  getOverlayEvent,
  setOverlayEvent,
} from "@/feature-battles/event-hooks-util.mjs";
import type {
  BattlesEvent,
  MissionCriteria,
} from "@/feature-battles/model-events.mjs";
import GameRecordModel from "@/feature-battles/model-game-record.mjs";
import battlesRefs from "@/feature-battles/refs.mjs";
import type { EventMap, EventQueue } from "@/feature-battles/util-game.mjs";
import {
  getCriteriaDetails,
  mapIdToEventMap,
  queueIdToEventQueue,
} from "@/feature-battles/util-lol.mjs";
import { filterCriteria } from "@/feature-battles/utils.mjs";
import * as API from "@/game-lol/apis/api.mjs";
import { GAME_SYMBOL_LOL } from "@/game-lol/definition-symbol.mjs";
import {
  extractNameFromDerivedId,
  getDerivedId,
  getSummonerFromDerivedId,
  separateDerivedRiotID,
} from "@/game-lol/utils/derived-id.mjs";
import getCurrentSummoner from "@/game-lol/utils/get-current-summoner.mjs";
import QueueSymbol from "@/game-lol/utils/symbol-queue.mjs";
import { isPBE } from "@/game-lol/utils/util.mjs";
import { appDebug, devError } from "@/util/dev.mjs";
import lruObject from "@/util/lru-object.mjs";

const handleGameChange =
  ({ postMatch }) =>
  ({ gameId }) => {
    const {
      user,
      lol: { lobbyMembers },
    } = readState;

    const [region, name] = getCurrentSummoner();
    const summonerDerivedId = getDerivedId(region, name);

    if (isPBE(region)) return;

    appDebug("[BATTLES]: lol game record handler", {
      gameId,
      postMatch,
      userId: user?.id,
      lobbyMembers,
      summonerDerivedId,
    });

    if (user) {
      if (!region || !name)
        throw new Error(
          `Failed to find logged in account ${summonerDerivedId}`,
        );

      return createLeagueGameRecord({
        postMatch,
        data: {
          gameId,
          userAccountId: user.id,
          summonerDerivedId,
          premadeDerivedIds: lobbyMembers,
        },
      });
    }
  };

export const onEnterGame = handleGameChange({ postMatch: false });

export const onExitGame = handleGameChange({ postMatch: true });

export async function createLeagueGameRecord({
  postMatch,
  data: { gameId, userAccountId, summonerDerivedId, premadeDerivedIds },
}) {
  const [region, name] = getSummonerFromDerivedId(summonerDerivedId);
  const [gameName, tagLine] = separateDerivedRiotID(name);

  const profile = await API.PLAYER.getProfile({
    region,
    gameName,
    tagLine,
    options: { skipSafetyCheck: true },
  });

  if (!profile) return;

  const premadePuuids = (
    await Promise.all(
      premadeDerivedIds.map((premadeDerivedId) => {
        const name = extractNameFromDerivedId(premadeDerivedId);
        if (!name) return Promise.resolve();
        const [gameName, tagLine] = separateDerivedRiotID(name);
        return API.PLAYER.getProfile({
          region,
          gameName,
          tagLine,
          options: { skipSafetyCheck: true },
        });
      }),
    )
  ).map((premadeProfile) => premadeProfile.puuid);

  appDebug("[BATTLES]: lol game record handler - sending", {
    gameId,
    postMatch,
    gameAccountId: profile.id,
  });

  const bearerToken = await getBearerToken();
  const securityHeader = await getHashedSecurityHeader({
    userId: userAccountId,
    gameAccountId: profile.id,
  });

  return postData(
    sendLeagueGameRecord({
      gameId: `${gameId}`,
      region: region.toUpperCase(),
      premadePuuids,
      postMatch,
      userAccountId,
      leagueProfileId: profile.id,
    }),
    GameRecordModel,
    undefined,
    {
      headers: {
        Authorization: bearerToken,
        "blitz-challenge-record-id": securityHeader,
      },
    },
  );
}

export async function getLeagueOverlayInfo({
  queueId,
  mapId,
}: {
  queueId: string | number;
  mapId: string | number;
}): Promise<void> {
  appDebug(`[BATTLES]: lol overlay handler`, { queueId, mapId });
  queueId = queueId ? `${queueId}` : null;
  mapId = Number.parseInt(mapId as string);
  const mapName = mapIdToEventMap(mapId);
  let queueName = queueIdToEventQueue(QueueSymbol(queueId));

  if (battlesRefs.allowCustomGames && queueName === UNHANDLED_QUEUE)
    queueName = ALL_QUEUES;

  if (
    !readState.user ||
    queueName === UNHANDLED_QUEUE ||
    mapName === UNHANDLED_MAP
  ) {
    return setOverlayEvent(null);
  }

  const foundEvent = await getOverlayEvent({
    gameSymbol: GAME_SYMBOL_LOL,
    mapName,
    queueName,
  });

  // TODO: make mapEvent game agnostic & move to getOverlayEvent
  const mappedEvent = await mapEvent(foundEvent, queueName, mapName);
  return setOverlayEvent(mappedEvent);
}

// TODO: filter out finished non-repeatable missions
async function mapEvent(
  event: BattlesEvent,
  queueName: EventQueue,
  mapName: EventMap,
): Promise<OverlayEventInfo> {
  if (!event) return null;

  const getAssetsPromise = getOverlayAssets(
    event.id,
    event.assets?.overlayLogoImgUrl,
    event.nativeAdUnit?.logoImg,
  );
  const missions = event.missions
    .map((mission) => ({
      id: mission.id,
      displayName: mission.title,
      points: mission.blitzPointValue ?? 0,
      criteriaEvents: mission.criteria
        .filter(filterCriteria(queueName, mapName))
        .map(mapCriteria)
        .filter(Boolean),
    }))
    .filter(({ criteriaEvents }) => criteriaEvents.length);

  const { logo, icon } = await getAssetsPromise;
  return {
    title:
      event.displayName.length > 15
        ? `${event.displayName.substring(0, 15)}...`
        : event.displayName,
    remainingGames: 1, // TODO: find out what RE uses this for
    logoUrl: logo,
    iconUrl: icon,
    missions,
  };
}

interface OverlayAssets {
  logo: string;
  icon: string;
}
async function getAsset(url: string): Promise<string> {
  try {
    // eslint-disable-next-line blitz/prefer-get-data
    const data = await fetch(url);
    const blob = await data.blob();

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(
          (reader.result as string).replace(
            /data:image\/(png|jpeg|webp);base64,/gi,
            "",
          ),
        );
      };
      reader.readAsDataURL(blob);
    });
  } catch (e) {
    devError(`Failed to convert asset for event ${url}`, e);
    return null;
  }
}

const defaultAssets = {
  logo: null,
  icon: null,
};
const assetsMemo: Record<string, OverlayAssets> = lruObject({}, 3);
async function getOverlayAssets(
  eventId: string,
  logoUrl: string,
  iconUrl: string,
): Promise<OverlayAssets> {
  if (assetsMemo[eventId]) return assetsMemo[eventId];

  if (!defaultAssets.logo || defaultAssets.icon) {
    defaultAssets.logo = getAsset(`${appURLs.CDN}/${DEFAULT_OVERLAY_LOGO}`);
    defaultAssets.icon = getAsset(`${appURLs.CDN}/${DEFAULT_OVERLAY_ICON}`);
  }

  const [logo, icon, defaultLogo, defaultIcon] = await Promise.all([
    logoUrl && getAsset(`${appURLs.BATTLES_CDN}/${logoUrl}`),
    iconUrl && getAsset(`${appURLs.BATTLES_CDN}/${iconUrl}`),
    defaultAssets.logo,
    defaultAssets.icon,
  ]);

  const assets = { logo: logo || defaultLogo, icon: icon || defaultIcon };
  assetsMemo[eventId] = assets;
  return assets;
}

function mapCriteria(criteria: MissionCriteria): OverlayCriteria {
  const { amount, criteriaType, displayName, points } =
    getCriteriaDetails(criteria) ?? {};

  if (criteriaType === Criteria.multiKill) {
    return {
      displayName,
      event: multiKillToEventMap[amount],
      amount: 1,
      points,
    };
  }

  const eventType = criteriaToEventMap[criteriaType];
  if (!eventType) return null;

  return {
    displayName,
    event: eventType,
    amount,
    points,
  };
}
